export class CustomerProduct{
    nmpessoa: string;
    dssobrenome: string;
    dscep: string;
    dslogradouro: string;
    dsbairro: string;
    dscidade: string;
    dsuf: string;
    nbendereco: string;
    dscomplemento: string;
    dsemail: string;
    dstelefone: string;
    cdlicenca: string;
    dscpfcnpj: string;
    dtnascimento: Date;
    idpais: number;
    dtinicioativacao: Date;
}